<template>
  <v-menu
    ref="menuTime"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="formattedTime"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedTime"
        append-icon="mdi-clock-time-four-outline"
        readonly
        dense
        outlined
        color="secondary"
        v-bind="textFieldProps"
        v-on="on"
        clearable
        :rules="rules"
        @click:clear="clearTime"
      >
        <template v-slot:label>
          {{ label }}
          <span v-if="required" class="red--text"
          ><strong>*</strong></span
          >
        </template>
      </v-text-field>
    </template>
    <v-time-picker
      v-if="menu"
      v-model="formattedTime"
      v-bind="timePickerProps"
      full-width
      color="secondary"
      @click:minute="handleMinuteClick"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    textFieldProps: {
      type: Object,
      default: () => ({})
    },
    timePickerProps: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      menu: false,
      internalTime: null
    };
  },
  computed: {
    formattedTime: {
      get() {
        return this.value && moment(this.value, moment.ISO_8601, true).isValid()
          ? moment(this.value).format('HH:mm')
          : '';
      },
      set(time) {
        if (time && moment(time, 'HH:mm', true).isValid()) {
          this.$emit('input', this.createIsoTime(time));
        } else {
          this.$emit('input', time === '' ? '' : null);
        }
      }
    }
  },
  methods: {
    createIsoTime(time) {
      if (time && moment(time, 'HH:mm', true).isValid()) {
        // Suponiendo que quieres usar la fecha actual para la parte de la fecha
        const datePart = moment().format('YYYY-MM-DD');
        // Combina la fecha y la hora sin aplicar zona horaria
        return `${datePart}T${time}:00.000`;
      }
      return null;
    },
    handleMinuteClick() {
      this.menu = false;
      this.$refs.menuTime.save(this.formattedTime);
    },
    clearTime() {
      this.$emit('input', null);
    }
  },
  watch: {
    value(newVal) {
      if (newVal !== this.createIsoTime(this.formattedTime)) {
        this.internalTime = newVal;
      }
    }
  },
  mounted() {
    this.internalTime = this.value;
  }
};
</script>
