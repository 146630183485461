import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{ref:"menuDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","color":"secondary","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"append-icon":"mdi-calendar","readonly":"","dense":"","outlined":"","color":"secondary","clearable":"","rules":_vm.rules},on:{"click:clear":_vm.clearDate},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},'v-text-field',_vm.textFieldProps,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,_vm._b({attrs:{"no-title":"","scrollable":"","color":"secondary"},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}},'v-date-picker',_vm.datePickerProps,false),[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.clean'))+" ")]),_c(VBtn,{attrs:{"text":"","color":"secondary"},on:{"click":_vm.saveDate}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.confirm'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }