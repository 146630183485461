<template>
  <v-menu
    ref="menuDate"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    color="secondary"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        append-icon="mdi-calendar"
        readonly
        dense
        outlined
        color="secondary"
        v-bind="textFieldProps"
        v-on="on"
        clearable
        :rules="rules"
        @click:clear="clearDate"
      >
        <template v-slot:label>
          {{ label }}
          <span v-if="required" class="red--text"
          ><strong>*</strong></span
          >
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="formattedDate"
      v-bind="datePickerProps"
      no-title
      scrollable
      color="secondary"
    >
      <v-spacer></v-spacer>
      <v-btn text color="grey" @click="menu = false">
        {{ $t('general.buttons.clean') }}
      </v-btn>
      <v-btn text color="secondary" @click="saveDate">
        {{ $t('general.buttons.confirm') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    textFieldProps: {
      type: Object,
      default: () => ({})
    },
    datePickerProps: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      menu: false,
      formattedDate: this.formatDate(this.value)
    };
  },
  methods: {
    formatDate(isoDate) {
      if (isoDate && moment(isoDate, moment.ISO_8601, true).isValid()) {
        return moment(isoDate).format('YYYY-MM-DD');
      }
      return '';
    },
    saveDate() {
      this.menu = false;
      if (this.formattedDate && moment(this.formattedDate, 'YYYY-MM-DD', true).isValid()) {
        this.$emit('input', this.formattedDate + 'T00:00:00.000');
      } else {
        this.$emit('input', null);
      }
    },
    clearDate() {
      this.formattedDate = '';
      this.$emit('input', null);
    },
  },
  watch: {
    value(newVal) {
      this.formattedDate = this.formatDate(newVal);
    },
    formattedDate(newDate) {
      if (newDate !== this.formatDate(this.value)) {
        this.$emit('input', newDate ? newDate + 'T00:00:00.000' : null);
      }
    }
  }
};
</script>
