import { render, staticRenderFns } from "./NomenclatorComponent.vue?vue&type=template&id=f75a5cee&scoped=true&"
import script from "./NomenclatorComponent.vue?vue&type=script&lang=js&"
export * from "./NomenclatorComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f75a5cee",
  null
  
)

export default component.exports